import { render, staticRenderFns } from "./Pedagogico.vue?vue&type=template&id=17acfb1f&scoped=true&"
import script from "./Pedagogico.vue?vue&type=script&lang=ts&"
export * from "./Pedagogico.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17acfb1f",
  null
  
)

export default component.exports